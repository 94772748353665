'use client'
import LinkComponent from "@components/LinkComponent"


const FooterPopularLinks = ({ content, dictionary, lang }) => {
    

    return (
        <div className="pl-2">
            <span className='font-bold'>{content.title}</span>
            <ul>
                {content.links && content.links.map((link, index) => {
                    return (
                        <li key={index} className='w-full my-1'>
                            <LinkComponent href={link.url} className='hover:color-forth'>
                                {link.title}
                            </LinkComponent>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default FooterPopularLinks
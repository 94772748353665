'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { i18n } from '../i18n-config'
import { setCookie, getCookie, getCookies } from 'cookies-next';
import { BiWorld } from "react-icons/bi";
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FaGlobe } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";

export default function MobileChineseLocaleSwitcher({ language, dictionary }) {
  const [currentLocale, setCurrentLocale] = useState(language)
  const [selected, setSelected] = useState(language)
  const [isOpen, setIsOpen] = useState(false)
  const pathName = usePathname()
  
  const redirectedPathName = (locale) => {
    const languages = ['en', 'th', 'cn']

    if (!pathName) return '/'
    if (languages.includes(pathName.split('/')[1])) {
      const segments = pathName.split('/')
      segments[1] = locale
      return segments.join('/')
    } else {
      return `/${locale}` + pathName
    }
  }

  // console.log('hu '+ getCookie('NEXT_LOCALE'))
  // console.log('hu ' + currentLocale)


  const setLocaleCookie = (localeValue) => {
    setCookie('NEXT_LOCALE', localeValue);
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }


  return (
    <Link className='' onClick={() => setLocaleCookie('cn')} href={redirectedPathName('cn')}>
      <div className='text-light py-1 px-3 border-[1px] border-light sm:rounded-sm rounded-md'>
        中文网站
      </div>
    </Link>
  )
}
export const company =
{
    name: 'RE/MAX Capital Property',
    heroTitle: 'Your Koh Samui Property Solution',
    mainServiceArea: 'Pattaya',
    phone1: '+66 33 135 376 (ENG/TH)',
    // phone2: '081 969 4950 (ENG/CN)',
    email: 'info@pattayapropertyspace.com',
    address1: '24/56 Moo 5 Nongprue, Pattaya',
    address2: 'Chonburi, Thailand 20150',
    phoneUrl: 'tel:006633135376',
    lineUrl: 'https://lin.ee/vbL0kMGy',
    whatsappUrl: 'https://wa.me/6633135376',
    youtubeUrl: 'https://www.youtube.com/@remaxcapitalpropertypattay87',
    facebookUrl: 'https://www.facebook.com/remaxcapitalpropertiespattaya',
    instagramUrl: '',
    tiktokUrl: '',
    linkedInUrl: '',
    OFFICE_LAT: 12.954054,
    OFFICE_LNG: 100.88996
}

export const updateSearchParams = (type, value) => {
    // Get the current URL search params
    const searchParams = new URLSearchParams(window.location.search);

    // Set the specified search parameter to the given value
    searchParams.set(type, value);

    // Set the specified search parameter to the given value
    const newPathname = `${window.location.pathname}?${searchParams.toString()}`;

    return newPathname;
};

export const updateBtsParams = (value, latitude, longitude) => {
    // Get the current URL search params
    const searchParams = new URLSearchParams(window.location.search);

    // Set the specified search parameter to the given value
    searchParams.set('bts', value);
    searchParams.delete('latitude');
    searchParams.delete('longitude');

    // Set the specified search parameter to the given value
    const newPathname = `${window.location.pathname}?${searchParams.toString()}&latitude=${latitude}&longitude=${longitude}`;

    return newPathname;
};

export const updateDistrictParams = (value) => {
    // Get the current URL search params
    const searchParams = new URLSearchParams(window.location.search);

    // Set the specified search parameter to the given value
    searchParams.set('district', value);


    // Set the specified search parameter to the given value
    const newPathname = `${window.location.pathname}?${searchParams.toString()}`;

    return newPathname;
};

export const updateDistrictsParams = (value) => {
    // Get the current URL search params
    const searchParams = new URLSearchParams(window.location.search);

    // Set the specified search parameter to the given value
    searchParams.set('districts', value);


    // Set the specified search parameter to the given value
    const newPathname = `${window.location.pathname}?${searchParams.toString()}`;

    return newPathname;
};

export const getSearchParams = (type) => {
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(type)
    // console.log(value)
    return value
}

export const removeSearchParam = (paramName) => {
    // Get the current URL search params
    const searchParams = new URLSearchParams(window.location.search);

    // Remove the specified search parameter
    searchParams.delete(paramName);

    const newPathname = `${window.location.pathname}?${searchParams.toString()}`;

    return newPathname;
}

export const removeParams = () => {
    let mapQuery = ''
    const map = getSearchParams('map')
    if (map !== null) mapQuery = `&map=${map}`

    window.location.search = `${mapQuery}`
}

export const formatDate = (dateString) => {

    const [year, month, day] = dateString.split('-');

    const formattedDay = parseInt(day, 10).toString();

    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const formattedDate = `${formattedDay} ${monthNames[parseInt(month, 10) - 1]} ${year}`;

    return formattedDate
}

export const getMapLanguage = (language = 'en') => {
    let lang = 'en'
    switch (language) {
        case 'en':
            lang = 'en'
            break;
        case 'cn':
            lang = 'zh'
            break;
        case 'th':
            lang = 'th'
            break;
        default:
            lang = 'en'
            break;
    }

    return lang
}
export const getMapRegion = (language = 'en') => {
    let reg = 'US'
    switch (language) {
        case 'en':
            reg = 'US'
            break;
        case 'cn':
            reg = 'ZH'
            break;
        case 'th':
            reg = 'TH'
            break;
        default:
            reg = 'US'
            break;
    }

    return reg
}

export const priceFormatter = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}